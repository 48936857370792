<template lang="html">
  <div
    class="header-avatar"
    v-bind:class="{ clicked: isMenuOpen }"
  >
    <div class="header-avatar__item">
      <v-tooltip
        :disabled="isOwnerOfWorkspace"
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="header-avatar__credits"
            v-bind="attrs"
            v-on="on"
          >
            <span class="header-avatar__credits-label"> {{ $t('general.documents') }}: </span>

            <v-avatar
              color="#6DECAF"
              class="mx-1"
              :size="'number' === typeof credits ? 25 : 30"
            >
              <span
                class="black--text font-weight-bold"
                style="font-size: 0.65rem"
              >
                {{ credits }}
              </span>
            </v-avatar>

            <span
              v-if="canSeeSubscription"
              class="header-avatar__credits-buy-new"
            >
              <a @click="$router.push({ name: 'subscription' })">
                {{ $t('contract.buy') }}
              </a>
            </span>
            <span
              v-if="isPartnersClient"
              class="header-avatar__credits-buy-new"
            >
              <a
                href="https://signi.com/odeslat-poptavku/"
                target="_blank"
              >
                {{ $t('contract.contact_store') }}
              </a>
            </span>
          </div>
        </template>

        <span>{{ $t('workspace.messages.cant_buy_credits') }}</span>
      </v-tooltip>

      <div class="header-avatar__help px-4">
        <AppHelpCenter />
      </div>

      <div class="px-4">
        <AppUserMenu />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHelpCenter from '@/layouts/components/AppHelpCenter';
import AppUserMenu from '@/layouts/components/AppUserMenu';

export default {
  name: 'HeaderAvatar',
  components: {
    AppHelpCenter,
    AppUserMenu,
  },
  data() {
    return {
      menuOpen: false,
      helpOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      workspaceById: 'workspaceById',
      isPartnersClient: 'isPartnersClient',
    }),
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id || localStorage.getItem('workspace_id'));
    },
    userName() {
      return `${this.profile.firstname} ${this.profile.lastname}`;
    },
    userAvatar() {
      return this.profile.avatar_url || null;
    },
    isMenuOpen() {
      return this.menuOpen;
    },
    isOwnerOfWorkspace() {
      return this.activeWorkspace?.is_owner || false;
    },
    canSeeSubscription() {
      if (this.isPartnersClient) {
        return false;
      }

      return this.isOwnerOfWorkspace;
    },
    credits() {
      const presentedCredits =
        'legal' === this.activeWorkspace?.type ? this.activeWorkspace?.owner_credits || 0 : this.profile?.credits || 0;

      return presentedCredits < 999 ? presentedCredits : '999+';
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.header-avatar {
  position: relative;
  padding-left: 20px;
}

.header-avatar__items {
  z-index: 9;
  display: none;
  padding: 20px 30px !important;
  position: absolute;
  top: 100%;
  right: 0.5rem;
  list-style-type: none;
  background-color: #fff;
  box-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.04);
  width: 230px;
}

.clicked .header-avatar__items {
  display: block !important;
}

.clicked .icon-bottom {
  transform: rotate(180deg);
  display: block;
}

.header-avatar__items a {
  display: block;
  color: #000000;
  padding: 15px 0;
  text-align: left;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: -0.03px;
}

.header-avatar__items li {
  border-top: 1px solid #f2f6fc;
}
.header-avatar__items li:first-child {
  border-top: none;
}

.header-avatar__item {
  height: 60px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    text-decoration: none;
  }
}

.header-avatar__img {
  display: inline-block;
  width: 35px;
  height: 35px;
  vertical-align: middle;
  z-index: 1;
  // float: left;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 35px;
  background-color: #6decb0;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
}

.header-avatar__name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  margin: 0 8px 0 12px;
  color: #000000;
  letter-spacing: -0.03px;
  font-weight: 400;

  @include md {
    display: none;
  }
}

.header-avatar__ico {
  display: inline-block;
  font-size: 8px;
  padding-top: 4px;

  @include md {
    padding-left: 2px;
  }
}

.header-avatar-ul-name {
  display: none;
}
.clicked {
  @include md {
    .header-phone-wrap {
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 9;
      height: 100%;
      width: 100%;
      background: white;
      overflow: scroll;
      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        display: inline-block;
        width: 40px;
        height: 22px;
        background-image: url('@/assets/images/contract-shape-top-left-desktop.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0 0;
        z-index: 3;
      }
    }
    .header-avatar-ul-name {
      display: block;
      padding: 110px 30px 0 30px;
      font-size: 22px;
      color: #8e97a4;
      letter-spacing: -0.05px;
      font-weight: 100;
    }
    .header-avatar__items {
      top: 0px;
      box-shadow: none;
      position: relative;
    }
    .logout-link {
      cursor: pointer;
      position: fixed;
      max-width: 100%;
      left: 30px;
      right: 30px;
      bottom: 30px;

      @media screen and (max-height: 450px) {
        position: static;
      }
    }
    .header-avatar__item {
      position: relative;
      z-index: 99;
      right: 0px;
    }
    .logout-li {
      position: relative;
      height: 100px;
    }
  }
}
.logout-link {
  margin-top: 30px;
  background: #f2f6fc;
  padding: 10px 15px !important;
  font-weight: normal !important;
  font-size: 13px !important;
  border-radius: 6px;
}

.header-avatar a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.logout-li a {
  transition: 0.2s;
  &:hover {
    text-decoration: none;
    background: #d4dff0;
    transition: 0.2s;
  }
}
.header-avatar:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  background: #d4dff0;
  content: '';
  height: 60px;
}

.header-avatar__help {
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-right: 1px solid #d4dff0;
  @include md {
    display: none;
  }
  .header-avatar__help-inner {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 3px solid #7c4cb0;
    color: #7c4cb0;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .header-avatar__help-content {
    position: absolute;
    background: white;
    padding: 20px;
    border-radius: 6px;
    top: 65px;
    left: 40px;
    width: 250px;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  }
}

.header-avatar__credits {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d4dff0;
  height: 100%;
  padding-right: 20px;
  @include md {
    display: none;
  }
}
.header-avatar__credits-label {
  font-size: 15px;
  font-weight: 400;
  color: black;
}
.header-avatar__credits-amount {
  background: #6decaf;
  padding: 10px;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 10px;
  margin-right: 5px;
}
.header-avatar__credits-buy-new {
  a {
    font-size: 15px;
    font-weight: 400;
    color: #7c4cb0;
    text-decoration: underline;
  }
}

.header-avatar__help-content-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    width: 15px;
  }
}
.header-avatar__help-content > p > a {
  color: #7c4cb0;
  text-decoration: underline;
}
</style>
