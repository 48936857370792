<template>
  <Data
    :loader="isLoadingWorkspace"
    :showMenu="true"
  >
    <template v-slot:header>
      <div
        v-if="isMobileDevice"
        class="title title--contract-with-butt"
      >
        <h1>{{ $t('workspace.settings') }}</h1>
      </div>

      <div
        v-else
        class="title title--contract-with-butt"
        style="text-align: left; margin: -30px auto 0"
        :style="'team' != workspaceSelectedTab ? 'max-width: 845px;' : ''"
      >
        <span style="font-size: 15px; color: gray"> {{ $t('workspace.settings') }}: </span>
        <h1 style="text-align: left; margin-top: 10px">
          <strong>{{ selectedTab }}</strong>
        </h1>
      </div>
    </template>

    <div
      v-if="workspace.id"
      :class="{ 'workspace-mobile-tab-select': 'team' != workspaceSelectedTab }"
    >
      <div
        class="data__tab-select"
        v-bind:class="{ 'select-clicked': tabSelectClicked }"
        @click="displaySelect()"
      >
        {{ selectedTab }}
        <span class="data__tab-hamburger"></span>
      </div>
    </div>

    <div
      v-if="workspace.id"
      :class="{ 'tabs-component': 'team' != workspaceSelectedTab }"
    >
      <tabs
        :class="{ 'select-clicked': tabSelectClicked }"
        :options="{ defaultTabHash: 'sign-identity' }"
        :setTab="workspaceSelectedTab"
        :setSelectedTab="selectedTabHash !== workspaceSelectedTab ? `#` + workspaceSelectedTab : selectedTabHash"
        :showTabsButtons="isMobileDevice"
        @changed="tabChanged"
      >
        <tab
          id="sign-identity"
          :name="$t('workspace.identity')"
        >
          <div>
            <template v-if="String(workspace.type) === 'nature'">
              <form
                class="my-5"
                @submit.prevent="updateWorkspaceuser_private()"
              >
                <v-text-field
                  type="text"
                  name="workspace-title"
                  v-model="user_private.title"
                  :label="`${$t('workspace.title')} *`"
                  :error="$v.user_private.title.$error"
                  outlined
                  counter
                  maxlength="20"
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="firstname"
                  v-model="profile.firstname"
                  :label="$t('general.name')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="surname"
                  v-model="profile.lastname"
                  :label="$t('general.surname')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="email"
                  v-model="user_private.workspace_email"
                  :label="$t('general.email_simple')"
                  :error="$v.user_private.workspace_email.$error"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <SBirthDayPicker
                  v-model="user_private.number"
                  :label="`${$t('general.birth_date')} *`"
                  :locale="$i18n.locale"
                  class="mx-auto mb-8"
                  style="max-width: 22rem"
                />

                <v-text-field
                  type="text"
                  name="street"
                  v-model="user_private.organization_street"
                  :label="$t('company.street')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="city"
                  v-model="user_private.organization_city"
                  :label="$t('company.city')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="postcode"
                  v-model="user_private.organization_zip"
                  :label="$t('company.zip')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <div
                  class="mx-auto mb-10"
                  style="max-width: 22rem"
                >
                  <LanguageSelect
                    :label="$t('contract.create.locale')"
                    :locale="user_private.locale"
                    @onLocaleChanged="user_private.locale = $event"
                  ></LanguageSelect>
                </div>

                <WorkspaceCustomInput
                  :disabledNames="!workspace.is_owner"
                  :inputs="inputs"
                  @change="setCustomInput"
                />

                <WorkspaceOrganizationHeaderTemplate
                  :customFields="inputs"
                  :workspace="workspace"
                  @change="setOrganizationHeaderTemplate"
                />

                <div
                  v-if="workspace.is_owner"
                  class="text-center"
                >
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="workspaceLoader"
                    max-width="22rem"
                    width="100%"
                    x-large
                  >
                    {{ $t('workspace.save') }}
                  </v-btn>
                </div>
              </form>
            </template>

            <template v-if="workspace.type == 'citizen'">
              <form
                class="my-5"
                @submit.prevent="updateWorkspaceuser_citizen()"
              >
                <v-text-field
                  type="text"
                  name="workspace-title"
                  v-model="citizen.title"
                  :label="`${$t('workspace.title')} *`"
                  :error="$v.citizen.title.$error"
                  outlined
                  counter
                  maxlength="20"
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="firstname"
                  v-model="profile.firstname"
                  :label="$t('general.name')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="surname"
                  v-model="profile.lastname"
                  :label="$t('general.surname')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="email"
                  v-model="citizen.workspace_email"
                  :label="$t('general.email_simple')"
                  :error="$v.citizen.workspace_email.$error"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="organization-ico"
                  v-model="citizen.organization_ico"
                  :label="`${$t('company.ico')} *`"
                  :error="$v.citizen.organization_ico.$error"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="organization-dic"
                  v-model="citizen.organization_dic"
                  :label="$t('company.dic')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="street"
                  v-model="citizen.organization_street"
                  :label="$t('company.street')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="city"
                  v-model="citizen.organization_city"
                  :label="$t('company.city')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="postcode"
                  v-model="citizen.organization_zip"
                  :label="$t('company.zip')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <div
                  class="mx-auto mb-10"
                  style="max-width: 22rem"
                >
                  <LanguageSelect
                    :label="$t('contract.create.locale')"
                    :locale="citizen.locale"
                    @onLocaleChanged="citizen.locale = $event"
                  ></LanguageSelect>
                </div>

                <WorkspaceCustomInput
                  @change="setCustomInput"
                  :inputs="inputs"
                  :disabledNames="!workspace.is_owner"
                />

                <WorkspaceOrganizationHeaderTemplate
                  :workspace="workspace"
                  :customFields="inputs"
                  @change="setOrganizationHeaderTemplate"
                />

                <div
                  v-if="workspace.is_owner"
                  class="text-center"
                >
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="workspaceLoader"
                    max-width="22rem"
                    width="100%"
                    x-large
                  >
                    {{ $t('workspace.save') }}
                  </v-btn>
                </div>
              </form>
            </template>

            <template v-if="workspace.type == 'legal'">
              <form
                class="my-5"
                @submit.prevent="updateWorkspaceLegal()"
              >
                <v-text-field
                  type="text"
                  name="workspace-title"
                  v-model="legal.title"
                  :label="`${$t('workspace.title')} *`"
                  :error="$v.legal.title.$error"
                  :disabled="!workspace.is_owner"
                  outlined
                  counter
                  maxlength="20"
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="firstname"
                  v-model="profile.firstname"
                  :label="$t('general.name')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="surname"
                  v-model="profile.lastname"
                  :label="$t('general.surname')"
                  disabled
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="email"
                  v-model="legal.workspace_email"
                  :label="$t('general.email_simple')"
                  :error="$v.legal.workspace_email.$error"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="organization-name"
                  v-model="legal.organization_name"
                  :label="`${$t('company.title')} *`"
                  :error="$v.legal.organization_name.$error"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="organization-ico"
                  v-model="legal.organization_ico"
                  :label="`${$t('company.ico')} *`"
                  :error="$v.legal.organization_ico.$error"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="organization-dic"
                  v-model="legal.organization_dic"
                  :label="$t('company.dic')"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="position"
                  v-model="legal.position"
                  :label="$t('workspace.position')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="street"
                  v-model="legal.organization_street"
                  :label="$t('company.street')"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="city"
                  v-model="legal.organization_city"
                  :label="$t('company.city')"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-text-field
                  type="text"
                  name="postcode"
                  v-model="legal.organization_zip"
                  :label="$t('company.zip')"
                  :disabled="!workspace.is_owner"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <div
                  class="mx-auto mb-10"
                  style="max-width: 22rem"
                >
                  <LanguageSelect
                    :label="$t('contract.create.locale')"
                    :locale="legal.locale"
                    @onLocaleChanged="legal.locale = $event"
                  ></LanguageSelect>
                </div>

                <WorkspaceCustomInput
                  @change="setCustomInput"
                  :inputs="inputs"
                  :disabledNames="!workspace.is_owner"
                />

                <WorkspaceOrganizationHeaderTemplate
                  :workspace="workspace"
                  :customFields="inputs"
                  @change="setOrganizationHeaderTemplate"
                />

                <div class="text-center">
                  <v-btn
                    type="submit"
                    color="primary"
                    :loading="workspaceLoader"
                    max-width="22rem"
                    width="100%"
                    x-large
                  >
                    {{ $t('workspace.save') }}
                  </v-btn>
                </div>
              </form>
            </template>

            <template v-if="errorMessage.length">
              <ErrorMessage
                v-for="(message, index) in errorMessage"
                :key="index"
              >
                {{ message }}
              </ErrorMessage>
            </template>
          </div>
        </tab>

        <tab
          id="team"
          :name="$t('workspace.team.title')"
          v-if="workspace.type === 'legal'"
        >
          <WorkspaceSettingsTeam
            v-if="'team' === workspaceSelectedTab"
            :workspace="workspace"
            @reload-workspace="loadWorkspace"
          />
        </tab>

        <tab
          id="appearance"
          :name="$t('workspace.branding.title')"
        >
          <div class="appearance-block">
            <template v-if="workspace.appearance.has_branding">
              <AppearancePreview
                v-model="appearanceDialog"
                :appearance="workspace.appearance"
              />

              <ImageUpload
                name="logo"
                attribute="logo"
                :src="workspace.appearance.logo || ''"
                :url="`${uploadUrl}?attribute=logo`"
                :label="$t('workspace.branding.logo_web')"
                rounded
                @input="changeFileLogo"
                @updateWorkspace="reloadBranding()"
              />

              <ImageUpload
                name="logo_pdf"
                attribute="logoPdf"
                :src="workspace.appearance.logo_pdf || ''"
                :url="`${uploadUrl}?attribute=logo_pdf`"
                :label="$t('workspace.branding.logo_pdf')"
                rounded
                @input="changeFilePdfLogo"
                @updateWorkspace="reloadBranding()"
              />

              <ImageUpload
                name="background"
                attribute="background"
                :src="workspace.appearance.background || ''"
                :url="`${uploadUrl}?attribute=background`"
                :label="$t('workspace.branding.background')"
                :ratio="1920 / 1080"
                :minCropBoxWidth="1600"
                :minCropBoxHeight="900"
                :tip="$t('workspace.branding.background_tipHtml')"
                rounded
                @input="changeFileBackground"
                @updateWorkspace="reloadBranding()"
              />
            </template>

            <template>
              <form @submit.prevent="updateWorkspaceAppearance()">
                <ColorInput
                  v-if="workspace.appearance.has_branding"
                  v-model="appearance.primary_color"
                  :label="$t('workspace.branding.color')"
                  default="#934FDD"
                  @input="setColor"
                />

                <ColorInput
                  v-if="workspace.appearance.has_branding"
                  v-model="appearance.layerColor"
                  :label="$t('workspace.branding.color_layer')"
                  default="#FFFFFF"
                  @input="setLayerColor"
                />

                <ColorInput
                  v-if="workspace.appearance.has_branding"
                  v-model="appearance.textColor"
                  :label="$t('workspace.branding.color_text')"
                  default="#000000"
                  @input="setTextColor"
                />

                <v-checkbox
                  v-if="workspace.appearance.has_branding"
                  v-model="appearance.hide_terms"
                  :label="$t('workspace.branding.hide_terms')"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="appearance.uneditable_header"
                  :label="$t('workspace.settings.uneditable_header')"
                ></v-checkbox>

                <span class="input__label">
                  {{ $t('workspace.settings.redirect_url') }}
                </span>

                <v-text-field
                  type="text"
                  name="redirect-url"
                  v-model="appearance.url_redirect"
                  label="URL"
                  outlined
                ></v-text-field>

                <v-btn
                  v-if="workspace.is_owner"
                  type="submit"
                  color="primary"
                  :loading="workspaceLoader"
                  class="mt-5"
                  x-large
                  block
                >
                  {{ $t('workspace.save') }}
                </v-btn>
              </form>
            </template>
          </div>
        </tab>

        <tab
          id="messages"
          :name="$t('workspace.notifications.title')"
        >
          <template>
            <form class="form-fit-width">
              <div class="workspace-input">
                <div class="input">
                  <v-select
                    :value="notification.locale"
                    :label="$t('workspace.messages.language_select')"
                    :items="locales"
                    item-value="locale"
                    outlined
                    @change="changeNotificationLocale($event)"
                  >
                    <template v-slot:selection="{ item }">
                      <div class="language-toggle__choice--flag">
                        <LanguageFlag :locale-data="item" />
                      </div>
                      {{ item.locale.toUpperCase() }}
                    </template>

                    <template v-slot:item="{ item }">
                      <div class="language-toggle__choice--flag">
                        <LanguageFlag :locale-data="item" />
                      </div>
                      {{ item.locale.toUpperCase() }}
                    </template>
                  </v-select>
                </div>
              </div>

              <h2>
                {{ $t('workspace.notifications.emails') }}
              </h2>

              <Input
                type="text"
                class="workspace-input"
                v-model="notification.email_from_name"
                :label="$t('workspace.notifications.sender')"
              />

              <Input
                type="text"
                class="workspace-input"
                v-model="notification.email_subject"
                :label="$t('workspace.notifications.subject')"
              />

              <Textarea
                type="text"
                class="workspace-input"
                v-model="notification.email_content"
                :label="`${$t('workspace.notifications.email_content')}`"
                :hint="`${$t('workspace.notifications.email_tipHtml')}`"
              />

              <SubjectsCustomizing
                :workspace="workspace"
                :locale="notification.locale"
              />

              <h2>
                {{ $t('workspace.notifications.sms') }}
              </h2>

              <v-checkbox
                v-model="notification.sms_is_enabled"
                :label="$t('workspace.notifications.sms_send_new')"
              ></v-checkbox>

              <Textarea
                type="text"
                class="workspace-input"
                v-model="notification.sms_content"
                :label="`${$t('workspace.notifications.sms_content')}`"
                :hint="`${$t('workspace.notifications.sms_tipHtml')}`"
                :max-length="200"
              />

              <div
                v-if="workspace.is_owner"
                class="text-center"
              >
                <v-btn
                  color="primary"
                  min-width="14rem"
                  :loading="workspaceLoader"
                  x-large
                  @click="updateNotifications"
                >
                  {{ $t('workspace.save') }}
                </v-btn>
              </div>
            </form>
          </template>
        </tab>

        <tab
          id="settings"
          :name="$t('general.settings')"
        >
          <div class="appearance-block">
            <template>
              <v-form ref="workspaceSettingsForm">
                <v-select
                  v-model="settings.contracts.is2FAEnabled"
                  :items="available2FAContractTypes"
                  :label="$t('verification.legacy_workspace_settings')"
                  class="mb-5"
                  outlined
                  hide-details
                >
                </v-select>

                <v-select
                  v-model="settings.smsAuthBeforeDownload"
                  :items="available2FAContractTypes"
                  :label="$t('verification.sms_before_download')"
                  class="mb-5"
                  outlined
                  hide-details
                >
                </v-select>

                <v-select
                  v-model="settings.login2FaSettings.enabledType"
                  :items="available2FATypes"
                  :label="$t('workspace.2fa.title')"
                  outlined
                  hide-details
                  return-object
                >
                </v-select>

                <v-text-field
                  type="number"
                  name="draft-expiration-days"
                  v-model="settings.contracts.draftExpirationDays"
                  :label="$t('workspace.settings.draft_expiration')"
                  :error="draftExpirationDaysError"
                  class="mt-5"
                  outlined
                ></v-text-field>

                <v-text-field
                  type="number"
                  name="completed-expiration-days"
                  v-model="settings.contracts.completedExpirationDays"
                  :label="$t('workspace.settings.completed_expiration')"
                  outlined
                ></v-text-field>

                <v-textarea
                  v-model="settings.note"
                  :label="$t('workspace.control_list.note')"
                  outlined
                ></v-textarea>

                <v-text-field
                  type="text"
                  name="phone-number-prefix"
                  v-model="settings.defaultPhonePrefix"
                  :label="$t('workspace.default_prefix')"
                  outlined
                  class="mx-auto"
                  style="max-width: 22rem"
                ></v-text-field>

                <v-checkbox
                  v-model="settings.auto_add_sign"
                  :label="$t('workspace.settings.autosign')"
                  class="pl-2"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="settings.saveBiometricParameters"
                  :label="$t('workspace.settings.save_biometric_parameters')"
                  class="pl-2"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="settings.convertToPdfA"
                  :label="$t('document.convert_to_pdf_a.automatically')"
                  class="pl-2"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="settings.contracts.lockAfterSeal"
                  :label="$t('envelop.lock_after_seal')"
                  class="pl-2"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="settings.cantChangeSignatureDate"
                  :label="$t('workspace.settings.cant_change_signature_date')"
                  class="pl-2"
                  hide-details
                ></v-checkbox>

                <v-checkbox
                  v-model="settings.autoEnabledFindAnchors"
                  :label="$t('contract.signature_positions.preserve')"
                  class="pl-2"
                ></v-checkbox>

                <v-text-field
                  type="text"
                  v-model="settings.auto_sign_place"
                  :label="$t('workspace.settings.default_place')"
                  :error="autoSigniPlaceError"
                  :hint="$t('info.default_place')"
                  class="mt-5"
                  outlined
                >
                  <template v-slot:append>
                    <s-help :message="$t('info.default_place')"></s-help>
                  </template>
                </v-text-field>

                <WorkspaceSignatureTypeSetting v-model="workspace.settings.availableSignTypes" />

                <SignatureFooterSettings />

                <Input
                  class="workspace-input mb-5"
                  v-model="settings.google_drive_path"
                  :label="$t('workspace.drive.google_id')"
                  type="text"
                />

                <div
                  v-if="workspace.is_owner"
                  class="mb-3 mt-8"
                >
                  <WorkspaceOneDrive
                    :workspaceId="workspaceId"
                    :isOneDriveEnabled="isOneDriveEnabled"
                    @fetchData="fetchData"
                  />
                </div>

                <div
                  v-if="workspace.is_owner"
                  class="text-center"
                >
                  <v-btn
                    color="primary"
                    :loading="workspaceLoader"
                    :disabled="autoSigniPlaceError || draftExpirationDaysError"
                    x-large
                    block
                    @click="updateWorkspaceSettings()"
                  >
                    {{ $t('general.settings_save') }}
                  </v-btn>
                </div>
              </v-form>
            </template>
          </div>
        </tab>

        <tab
          id="verification"
          :name="$t('general.persons_verification')"
        >
          <WorkspaceSettingsVerification v-if="'verification' === workspaceSelectedTab" />
        </tab>
      </tabs>
    </div>

    <div
      v-if="workspace.id"
      class="workspaces-content"
    ></div>

    <div class="text-center">
      <v-btn
        v-if="workspace.id && workspace.is_owner"
        color="error"
        text
        @click.prevent="showDeleteWorkspaceDialog = true"
      >
        {{ $t('workspace.delete') }}
      </v-btn>
    </div>

    <SConfirmDialog
      v-model="showDeleteWorkspaceDialog"
      :message="`${$t('workspace.delete.confirm')}`"
      :info-note="accountDeleteWarning"
      @confirmation-callback="deleteWorkspace"
    />
  </Data>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { environment } from '@/config/environment';
import { getLocales } from '@/app/config/locales';
import { deleteWorkspace } from '@/features/workspace/services/workspaceService';
import { required, minLength, email } from 'vuelidate/lib/validators';
import { WorkspaceService } from '@/services/WorkspaceService.js';
import Tab from '@/components/Tab.vue';
import Tabs from '@/components/Tabs.vue';
import ImageUpload from '@/components/ImageUpload.vue';
import ColorInput from '@/components/ColorInput.vue';
import WorkspaceCustomInput from '@/components/WorkspaceCustomInput';
import WorkspaceOrganizationHeaderTemplate from '@/components/WorkspaceOrganizationHeaderTemplate';
import LanguageSelect from '@/common/components/LanguageSelect';
import SubjectsCustomizing from '@/features/workspace/components/SubjectsCustomizing';
import SBirthDayPicker from '@/common/components/SBirthDayPicker';
import SConfirmDialog from '@/common/components/SConfirmDialog';
import WorkspaceSettingsTeam from '@/features/workspace/views/WorkspaceSettingsTeam';
import WorkspaceSettingsVerification from '@/features/workspace/views/WorkspaceSettingsVerification';
import SignatureFooterSettings from '@/features/workspace/views/SignatureFooterSettings';
import LanguageFlag from '@/common/components/LanguageFlag';
import AppearancePreview from '@/features/workspace/components/AppearancePreview.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import WorkspaceOneDrive from '@/features/workspace/components/WorkspaceOneDrive';
import WorkspaceSignatureTypeSetting from '@/features/workspace/components/WorkspaceSignatureTypeSetting.vue';

export default defineComponent({
  name: 'WorkspaceSettings',
  components: {
    WorkspaceOneDrive,
    LanguageFlag,
    SignatureFooterSettings,
    SubjectsCustomizing,
    ErrorMessage,
    Tabs,
    Tab,
    ImageUpload,
    ColorInput,
    WorkspaceCustomInput,
    WorkspaceOrganizationHeaderTemplate,
    LanguageSelect,
    SBirthDayPicker,
    SConfirmDialog,
    WorkspaceSettingsTeam,
    WorkspaceSettingsVerification,
    AppearancePreview,
    WorkspaceSignatureTypeSetting,
  },
  setup() {
    const appearanceDialog = ref(false);

    return {
      appearanceDialog,
    };
  },
  data() {
    return {
      preparedUser: null,
      showDeleteWorkspaceDialog: false,
      searchTerm: '',
      autoSigniPlaceError: false,
      draftExpirationDaysError: false,
      isLoadingWorkspace: true,
      searchedValue: '',
      validForm: true,
      workspace: {},
      addPerson: false,
      errorMessage: false,
      workspaceTemplate: {
        title: '',
        firstname: '',
        lastname: '',
        organization_name: '',
        organization_ico: '',
        organization_dic: '',
        organization_id: '',
        position: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        organization_header_template: '',
        mobile: '',
        type: 'legal',
        locale: null,
      },

      legal: {
        title: '',
        organization_name: '',
        organization_ico: '',
        organization_dic: '',
        position: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'legal',
        workspace_email: '',
        locale: null,
      },
      user_private: {
        title: '',
        number: '',
        workspace_email: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'nature',
        locale: null,
      },
      citizen: {
        title: '',
        organization_ico: '',
        organization_dic: '',
        organization_street: '',
        organization_city: '',
        organization_zip: '',
        type: 'citizen',
        workspace_email: '',
        locale: null,
      },

      workspaceLoader: false,

      tabSelectClicked: false,
      selectedTab: '',
      selectedTabHash: '',

      appearance: {
        logo: '',
        background: '',
        primary_color: '#934FDD',
        layerColor: '#FFFFFF',
        textColor: '#000000',
        hide_terms: false,
        uneditable_header: false,
        url_redirect: '',
      },

      notification: {
        locale: null,
        email_from_name: '',
        email_subject: '',
        email_content: '',
        sms_content: '',
        sms_is_enabled: false,
      },

      settings: {
        auto_add_sign: false,
        autoEnabledFindAnchors: false,
        saveBiometricParameters: false,
        cantChangeSignatureDate: false,
        auto_sign_place: '',
        contracts: {
          is2FAEnabled: true,
          smsAuthBeforeDownload: false,
          draftExpirationDays: null,
          completedExpirationDays: null,
        },
      },

      inputs: [],
      organizationHeaderTemplate: '',
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      isMobileDevice: 'window/isMobileDevice',
      workspaceSelectedTab: 'userSettings/workspaceSelectedTab',
      workspaceById: 'workspaceById',
      workspaceSettings: 'workspace/settings',
    }),
    accountDeleteWarning() {
      const isLastUserWorkspace = 1 === this.profile?.workspaces?.filter((w) => w.is_owner)?.length;

      if (isLastUserWorkspace) {
        return this.$t('workspace.delete.account_warning');
      }

      return null;
    },
    activeWorkspace() {
      return this.workspaceById(this.$route.params.workspace_id);
    },
    locales() {
      return getLocales(false);
    },
    uploadUrl() {
      return `${environment.getApiUrl()}/api/v1/workspaces/${this.workspaceId}/upload`;
    },
    workspaceId() {
      return this.$route.params.workspace_id;
    },
    isOneDriveEnabled() {
      return this.workspace?.settings?.onedrive_enabled || false;
    },
    availableSignTypes() {
      return this.workspace?.settings?.availableSignTypes;
    },
    available2FATypes() {
      const disabled2FA = {
        text: this.$t(`workspace.2fa.disabled`),
        value: [],
      };

      const available2FA = this.workspace?.settings?.login2FaSettings?.allowedTypes.map((type) => {
        return {
          text: this.$t(`workspace.2fa.${type}`),
          value: [type],
          disabled: 'authenticator' === type ? true : false,
        };
      });

      return [disabled2FA, ...available2FA];
    },
    available2FAContractTypes() {
      return [
        {
          text: this.$t(`workspace.2fa.allowed`),
          value: true,
        },
        {
          text: this.$t(`workspace.2fa.disabled`),
          value: false,
        },
      ];
    },
  },
  validations: {
    user_private: {
      title: {
        required,
        minLength: minLength(2),
      },
      number: {
        required,
      },
      workspace_email: {
        email,
      },
    },
    citizen: {
      title: {
        required,
        minLength: minLength(2),
      },
      organization_ico: {
        required,
        minLength: minLength(2),
      },
      workspace_email: {
        email,
      },
    },
    legal: {
      title: {
        required,
        minLength: minLength(2),
      },
      organization_name: {
        required,
        minLength: minLength(2),
      },
      organization_ico: {
        required,
        minLength: minLength(6),
      },
      workspace_email: {
        email,
      },
    },
  },
  watch: {
    'settings.auto_sign_place': {
      handler: function () {
        this.autoSigniPlaceError = false;
      },
    },
    'settings.auto_add_sign': {
      handler: function () {
        this.autoSigniPlaceError = false;
      },
    },
    'settings.contracts.draftExpirationDays': {
      handler: function () {
        this.draftExpirationDaysError = false;
      },
    },
  },
  created() {
    this.fetchData();

    if (1 == this.$route.query.onedriveError) {
      this.$notification.error(this.$t('workspace.drive.onedrive_error'));
    }
  },
  beforeDestroy() {
    this.$store.dispatch('userSettings/setWorkspaceSelectedTab', 'sign-identity');
  },
  methods: {
    ...mapActions({
      fetchWorkspaceMembersPositions: 'workspace/fetchWorkspaceMembersPositions',
      fetchWorkspaceSettings: 'workspace/fetchSettings',
    }),
    updateWorkspaceLegal() {
      this.updateWorkspaceIdentity(this.$v.legal, this.legal);
    },
    updateWorkspaceuser_citizen() {
      this.updateWorkspaceIdentity(this.$v.citizen, this.citizen);
    },
    updateWorkspaceuser_private() {
      this.updateWorkspaceIdentity(this.$v.user_private, this.user_private);
    },
    updateWorkspaceIdentity(formValidation, identity) {
      this.errorMessage = [];
      formValidation.$touch();

      if (formValidation.$error) {
        this.errorMessage.push(this.$t('general.fill_required'));
        this.$notification.error(this.$t('general.fill_required'));
        return;
      }

      this.workspaceLoader = true;
      const workspaceData = {
        ...identity,
        inputs: this.inputs,
        organization_header_template: this.organizationHeaderTemplate,
      };

      WorkspaceService.update(workspaceData, this.workspaceId).then(
        (responseWorkspace) => {
          this.workspaceLoader = false;
          if (responseWorkspace.code) {
            this.$notification.error(this.$t('workspace.edit.failed'));
            this.errorMessage.push(this.$t('workspace.edit.failed'));
          } else {
            this.$notification.success(this.$t('workspace.edit.ok'));

            this.changeNotificationLocale(responseWorkspace.locale);
          }
        },
        () => {
          this.workspaceLoader = false;
          this.$notification.error(this.$t('workspace.edit.failed'));
        },
      );
    },
    fetchData() {
      this.isLoadingWorkspace = true;
      this.workspace = Object.assign({}, this.workspaceTemplate);

      this.fetchWorkspaceSettings(this.$route.params.workspace_id)
        .then(() => {
          this.workspace = Object.assign({}, this.workspaceSettings);

          this.appearance = this.workspace.appearance;
          this.changeNotificationLocale(this.workspace.locale);
          this.settings = this.workspace.settings;
          this.settings.smsAuthBeforeDownload =
            this.workspaceSettings?.feature_flags?.includes('smsAuthBeforeDownloadEnabled') || false;
          this.settings.cantChangeSignatureDate =
            this.workspaceSettings?.feature_flags?.includes('changeSignatureDateDisabled') || false;
          this.settings.autoEnabledFindAnchors =
            this.workspaceSettings?.feature_flags?.includes('autoFindAnchorsEnabled') || false;

          const enabledType = this.settings.login2FaSettings.enabledType;
          this.settings.login2FaSettings.enabledType = {
            text: this.$t(`workspace.2fa.${!enabledType.length ? 'disabled' : enabledType[0]}`),
            value: enabledType,
            disabled: 'authenticator' === enabledType[0] ? true : false,
          };

          this.inputs = this.workspace.custom_inputs ? this.workspace.custom_inputs : [];
          this.organizationHeaderTemplate = this.workspace.organization_header_template || '';

          this.selectedTabHash = '#sign-identity';

          if (!this.workspace.workspace_email) {
            this.workspace.workspace_email = this.workspace.email;
          }

          switch (this.workspace.type) {
            case 'nature':
              this.user_private = this.workspace;
              break;

            case 'citizen':
              this.citizen = this.workspace;
              break;

            case 'legal':
              this.legal = this.workspace;
              break;
          }
        })
        .catch(() => {
          this.$notification.error(this.$t('general.data_not_loaded'));
        })
        .finally(() => {
          this.isLoadingWorkspace = false;
        });
    },
    reloadBranding() {
      WorkspaceService.findOne(this.$route.params.workspace_id).then(
        (workspace) => {
          this.workspace.appearance = workspace.appearance;
        },
        () => {
          this.$notification.error(this.$t('general.data_not_loaded'));
        },
      );
    },
    changeValue: function (newValue) {
      this.workspace.type = newValue;
    },
    loadWorkspace() {
      WorkspaceService.getUsers(this.$route.params.workspace_id).then((response) => {
        this.workspace.allowed_users = response;
        this.fetchWorkspaceMembersPositions(this.$route.params.workspace_id);
      });
    },
    deleteWorkspace() {
      deleteWorkspace(this.workspace.id)
        .then(() => {
          const userWorkspace =
            this.profile?.workspaces.find((workspace) => {
              return workspace.is_owner && this.$route.params.workspace_id !== workspace.id;
            }) || null;

          if (userWorkspace) {
            return this.$router.push({
              name: 'dashboard',
              params: {
                workspace_id: userWorkspace.id,
              },
            });
          }

          this.$router.push({
            name: 'workspaceNew',
          });
        })
        .catch(() => {
          this.$notification.error(this.$t('workspace.delete.error'));
        });
    },
    displaySelect() {
      this.tabSelectClicked = !this.tabSelectClicked;
    },
    tabChanged(selectedTab) {
      this.selectedTab = selectedTab.tab.name;
      this.tabSelectClicked = false;

      window.location.hash = `#${selectedTab.tab.id}`;
    },
    updateWorkspaceAppearance() {
      this.errorMessage = [];
      this.workspaceLoader = true;

      if (this.settings.url_redirect && !this.validURL(this.settings.url_redirect)) {
        this.$notification.error(this.$t('workspace.settings.redirect_url_invalid'));
        this.workspaceLoader = false;

        return;
      }

      WorkspaceService.updateAppearance(this.appearance, this.workspace.id).then(
        (response) => {
          this.workspaceLoader = false;

          if (response.code) {
            this.errorMessage.push(this.$t('workspace.edit.failed'));
            this.$notification.error(this.$t('workspace.edit.failed'));
          } else {
            this.$notification.success(this.$t('workspace.edit.ok'));
          }
        },
        () => {
          this.workspaceLoader = false;
          this.$notification.error(this.$t('workspace.edit.failed'));
        },
      );
    },
    changeNotificationLocale(locale) {
      const localeIndex = this.findLocaleIndex(locale);

      if (localeIndex != -1) {
        this.notification = this.workspace.notifications[localeIndex];
      }
    },
    updateNotifications() {
      this.workspaceLoader = true;
      this.errorMessage = [];

      WorkspaceService.updateNotifications(this.notification, this.workspace.id).then(
        () => {
          const localeIndex = this.findLocaleIndex(this.notification.locale);
          if (localeIndex != -1) {
            this.workspace.notifications[localeIndex] = {
              ...this.notification,
            };
          }
          this.workspaceLoader = false;
          this.$notification.success(this.$t('workspace.edit.ok'));
        },
        () => {
          this.workspaceLoader = false;
          this.$notification.error(this.$t('workspace.edit.failed'));
        },
      );
    },
    findLocaleIndex(locale) {
      return this.workspace.notifications.findIndex((n) => n.locale == locale);
    },
    updateWorkspaceSettings() {
      this.$refs.workspaceSettingsForm.validate();

      if (
        '' === this.settings.contracts.draftExpirationDays ||
        parseInt(this.settings.contracts.draftExpirationDays) <= 0
      ) {
        this.draftExpirationDaysError = true;
        return;
      }

      if ('' === this.settings.auto_sign_place && this.settings.auto_add_sign) {
        this.autoSigniPlaceError = true;
        return;
      }

      this.workspaceLoader = true;
      this.errorMessage = [];

      this.settings.login2FaSettings.enabledType = this.settings.login2FaSettings.enabledType.value;

      WorkspaceService.updateSettings(this.settings, this.workspace.id).then(
        (workspace) => {
          const enabledType = workspace.settings.login2FaSettings.enabledType;
          this.settings.login2FaSettings.enabledType = {
            text: this.$t(`workspace.2fa.${!enabledType.length ? 'disabled' : enabledType[0]}`),
            value: enabledType,
            disabled: 'authenticator' === enabledType[0] ? true : false,
          };

          this.workspaceLoader = false;

          if (workspace.code) {
            this.$notification.error(this.$t('workspace.edit.failed'));
            this.errorMessage.push(this.$t('workspace.edit.failed'));
            return;
          }

          this.$notification.success(this.$t('workspace.edit.ok'));
        },
        () => {
          this.workspaceLoader = false;
          this.$notification.error(this.$t('workspace.edit.failed'));
        },
      );
    },
    validURL(str) {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(str);
    },
    changeFileLogo(files) {
      this.workspaceLoader = true;
      this.workspace.appearance.logo = files[0].response;
      this.workspaceLoader = false;
    },
    changeFilePdfLogo(files) {
      this.workspaceLoader = true;
      this.workspace.appearance.logo_pdf = files[0].response;
      this.workspaceLoader = false;
    },
    changeFileBackground(files) {
      this.workspaceLoader = true;
      this.workspace.appearance.background = files[0].response;
      this.workspaceLoader = false;
    },
    setColor(hex) {
      this.appearance.primary_color = hex;
    },
    setLayerColor(hex) {
      this.appearance.layerColor = hex;
    },
    setTextColor(hex) {
      this.appearance.textColor = hex;
    },
    setCustomInput(inputs) {
      this.inputs = inputs;
    },
    setOrganizationHeaderTemplate(header) {
      this.organizationHeaderTemplate = header;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.activeWorkspace.is_owner) {
        return vm.$router.push({
          name: 'dashboard',
          params: {
            workspace_id: to.params.workspace_id,
          },
        });
      }
    });
  },
});
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.workspace-checkbox {
  margin-top: 43px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  margin-left: -62px; // negative margin to shift left from the center point
}

.add-person-info {
  max-width: 100%;
  text-align: left;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  border-bottom: solid 1px #d4dff0;

  p {
    font-size: 13px;
  }
}

.error-message {
  text-align: center;
}

.workspace-radios {
  margin-top: 20px;
}
.workspace__buttons {
  text-align: center;
  padding-bottom: 60px;
}

.workspace-identity {
  width: 100%;
  float: left;
  padding-top: 40px;
  padding-bottom: 30px;
}

.workspace-input.input {
  width: 330px;
}

.workspace-input .input {
  width: 320px;
  @include sm {
    max-width: 320px;
    width: 100%;
  }
}
.form-fit-width .workspace-input .input {
  width: 100%;
  max-width: 100%;
}

.workspaces-content {
  margin: 4em 0;
  width: 770px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15) !important;
  border-radius: 6px !important;
  border: none !important;
  background: white;
}

.workspace-content__body {
  padding: 40px 25px;
}

.workspace-half {
  width: 50%;
  float: left;
  padding: 0px 15px;
}

.add-person-wrap {
  height: 56px;
  position: relative;
}

.workspace-mobile-tab-select {
  margin-top: 40px;
}

.appearance-block {
  width: 100%;
  max-width: 340px;
  margin: auto;
}

.language-toggle__choice--flag {
  overflow: hidden;
  margin-right: 0.25rem;

  svg {
    overflow: hidden;
    width: 30px;
    max-height: 30px;
    zoom: 0.8;
    border: none;
    border-radius: 8px;
  }
}
</style>
